import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { SEO } from "components"

const Container = styled("div")`
  text-align-center;
  margin: auto;
  margin-top: 128px;
  text-align: center;
`

const NotFoundPage = () => (
  <Container>
    <SEO title="404: Not found" />
    <h1>Oups, pas de page ici!</h1>
    <Link to="/">Retour sur la page d'accueil</Link>
  </Container>
)

export default NotFoundPage
